/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAuth from '../context/AuthContext';
import GetAccess from './GetAccess';
import { adminAPI } from '../api';

import DropdownMenu, { MenuItem } from '../components/DropdownMenu';
import OutsideDetector from '../hooks/outsideDetector';
import { userDropdownList } from '../helpers/localData';

import Intro from '../components/chat/Intro';
import Sender from '../components/chat/Sender';
import Recipient from '../components/chat/Recipient';
import SavedChats from '../components/chat/SavedChats';

import LogoIcon from '../../../../assets/images/alex-by-admired-leadership.svg';
import ArrowRight from '../../../../assets/images/arrow-right.png';

import useModal from '../context/ModalContext';
import AgreementAlex from './alex/AgreementAlex';
import PrivacyAlex from './alex/PrivacyAlex';

const prompts = [
  'Getting started with ALEX',
  'I’d like coaching on a challenge or opportunity',
  'I’d like help practicing a leadership skill',
  'I’d like to search or find Field Notes',
  'Tell me more about Admired Leadership',
];

export const footerMenu = [
  { href: 'https://www.admiredleadership.com/about/', text: 'About Us' },
  { href: '/v2/contact-us', text: 'Get Support' },
];

const UserMenu = ({ userProfile }) => {
  const [showLinkDropdown, setShowLinkDropdown] = useState(false);
  const { userData } = useAuth();
  const blankPicture = userData?.profile?.avatar?.url?.includes('blank');

  return (
    <OutsideDetector
      className="relative ml-auto"
      stateSetter={setShowLinkDropdown}
    >
      <button
        type="button"
        className={`p-2 rounded-2lg ml-4 ${
          showLinkDropdown && 'bg-purple-500'
        }`}
        onClick={() => setShowLinkDropdown(!showLinkDropdown)}
      >
        <div className="h-6 w-6 rounded-lg flex justify-center items-center bg-white overflow-hidden">
          {blankPicture ? (
            userData?.profile?.first_name?.charAt(0)
          ) : (
            <img src={userData?.profile?.avatar?.url} />
          )}
        </div>
      </button>
      {showLinkDropdown && (
        <DropdownMenu className="mt-3 pin-r" list={userDropdownList} />
      )}
    </OutsideDetector>
  );
};

const FirstLoginModal = () => {
  return (
    <>
      <p className="mb-4">
        Welcome to ALEX, your personal virtual executive coach from Admired
        Leadership. We're thrilled to have you join us as we explore this
        innovative form of leadership development.
      </p>
      <p className="mb-4">
        At the heart of ALEX is a highly capable AI assistant, powered by
        advanced language modeling technology and an extensive knowledge of
        Admired Leadership content. This means you should have a coaching
        experience that feels natural, intuitive, and personalized to your
        needs. ALEX can engage on a wide range of leadership topics, and can
        provide practical insights that help you grow as a leader.
      </p>
      <p className="mb-4">
        That said, this is beta. ALEX can be a little slow to respond, and we
        will be improving it over time. And as with any AI technology, ALEX has
        limitations. ALEX may say it can do things that it’s unable to do, and
        it may say things that are factually inaccurate. If this happens, let us
        know, and be sure to check any facts from ALEX that you might use in the
        real world. And while ALEX can offer valuable guidance and support, it's
        ultimately up to you to decide which insights resonate and how to best
        apply them given your leadership context.
      </p>
      <p className="mb-4">
        As you explore this new frontier of leadership development with us, we
        encourage you to remain open-minded, inquisitive, and reflective. Share
        your successes, challenges, and 'aha' moments along the way – your
        feedback will help us improve ALEX. And again, welcome! We're eager to
        see how ALEX can help you improve the people and situations you lead.
      </p>
    </>
  );
};

const ExpireSoonMessageModal = () => {
  return (
    <>
      <p className="mb-4">
        Access to this Beta instance of ALEX will be closed Friday, May 17.
        Thank you for taking time to experience ALEX and giving the Admired
        Leadership team your feedback.
      </p>
    </>
  );
};

const Chat = () => {
  const now = new Date();

  const { isLoading, isLoggedIn, expires_at } = useAuth();
  const sideBarRef = useRef(null);
  const { setContent } = useModal();

  const [showHistory, setShowHistory] = useState(false);
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState();
  const handleHistory = () => {
    setShowHistory(!showHistory);
  };
  const [liked, setLiked] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [messageId, setMessageId] = useState();

  // useEffect(() => {
  //   window.hsConversationsSettings = {
  //     loadImmediately: false,
  //   };

  //   window.hsConversationsOnReady = [
  //     () => {
  //       window.HubSpotConversations.widget.remove();
  //     },
  //   ];
  // }, []);

  const handleOutsideClick = (e) => {
    if (sideBarRef.current && !sideBarRef.current.contains(e.target)) {
      setShowHistory(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (expires_at?.substr(0, 10) === '2024-05-17') {
      setContent({
        titleCallout: 'ALEX Beta',
        modalTitle: 'ALEX Beta',
        content: <ExpireSoonMessageModal />,
      });
    }
  }, [expires_at]);

  useEffect(() => {
    if (isFirstAccess) {
      setContent({
        titleCallout: 'Welcome to ALEX',
        modalTitle: 'Welcome to ALEX',
        content: <FirstLoginModal />,
      });
    }
  }, [isFirstAccess]);

  const handleTextareaChange = useCallback((event) => {
    const actualValue = event.target.value;
    const newTotalCharacters = actualValue.length;

    if (newTotalCharacters > 20 || actualValue.includes('\n')) {
      event.target.classList.add('active');
    } else {
      event.target.classList.remove('active');
    }
  }, []);

  const handleTextareaKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessage(message);
      }
    },
    [message]
  );

  const loadConversation = useCallback(async (id) => {
    const conversation = await adminAPI.getConversation(id);
    setMessages(
      conversation.conversation_messages.map((message, index) => ({
        message: message.message,
        from: !!(index % 2) ? 'alex' : 'user',
        id: message.id,
        feedback: message.conversation_message_feedback,
      }))
    );
    setSessionId(conversation.session_id);
    setShowHistory(false);
  }, []);

  const startNewChat = useCallback(() => {
    setMessages([]);
    setMessage('');
    setSessionId();
    setShowHistory(false);
  }, []);

  const sendMessage = useCallback(
    (prompt) => {
      setMessages((messages) => [
        ...messages,
        { from: 'user', message: prompt },
      ]);
      setMessage('');
      adminAPI
        .sendMessage({
          message: prompt,
          sessionId,
        })
        .then((response) => {
          setSessionId(response.session_id);
          setMessages((messages) => [
            ...messages,
            { from: 'alex', message: response.message, id: response.id },
          ]);
        });
    },
    [message]
  );

  const sendFeedback = useCallback(
    (messageId, liked, feedback) => {
      adminAPI
        .sendMessageFeedback({
          message_id: messageId,
          liked: liked,
          feedback: feedback,
        })
        .then((response) => {
          response;
        });
    },
    [liked]
  );

  const onPromptSelect = useCallback((prompt) => {
    sendMessage(prompt);
  }, []);

  const onClickUserAgreement = () => {
    setContent({
      titleCallout: 'User Agreement',
      modalTitle: 'User Agreement',
      content: <AgreementAlex />,
    });
  };

  const onClickPrivacyPolicy = () => {
    setContent({
      titleCallout: 'Privacy Policy',
      modalTitle: 'Privacy Policy',
      content: <PrivacyAlex />,
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {isLoggedIn ? (
        <section id="chat" className="container">
          <div className="flex">
            {/* sidebar */}
            <div
              className={`chat-history w-full pr-4 pl-4 border-r border-gray-dark md:w-1/2 lg:w-1/4 lg:pt-12 lg:pl-0 ${
                showHistory ? 'active' : ''
              }`}
              ref={sideBarRef}
              onBlurCapture={() => {
                if (window.innerWidth > 425) {
                  setShowHistory(false);
                }
              }}
            >
              <div className="flex items-center justify-between">
                <a href="/" style={{ width: 'fit-content' }}>
                  <img
                    className="h-10 mb-8 lg:h-16"
                    src={LogoIcon}
                    alt="ALEX by Admired Leadership"
                  />
                </a>
                <button
                  className="flex items-center justify-center w-8 h-8 mt-4 mb-4 ml-auto lg:hidden"
                  onClick={handleHistory}
                >
                  <span
                    style={{ height: '2px', transform: 'rotate(45deg)' }}
                    className="absolute w-5 rounded bg-black"
                  />
                  <span
                    style={{ height: '2px', transform: 'rotate(-45deg)' }}
                    className="absolute w-5 rounded bg-black"
                  />
                </button>
              </div>
              <div className="flex gap-4 border-b border-gray-dark">
                <button className="chat-actions active">
                  <span>Saved chats</span>
                </button>
                {/* <button className="chat-actions">
                  <span>Bookmarks</span>
                </button> */}
              </div>
              {/* <p className="pt-8 text-sm">
                You do not have a chat history.{" "}
                <a href="">Create an account now</a> to save your chats with
                Leadership Coach.
              </p> */}
              <SavedChats
                onSelectConversation={loadConversation}
                onStartNewChat={startNewChat}
                currentSessionId={sessionId}
                setIsFirstAccess={setIsFirstAccess}
              />
              <div className="pb-6">
                <div className="flex flex-wrap gap-2 mb-6">
                  {footerMenu.map((link) => (
                    <a
                      href={link.href}
                      className="w-fitt text-xs text-charcoal"
                    >
                      {link.text}
                    </a>
                  ))}
                  <span
                    className="w-fitt text-xs text-charcoal cursor-pointer"
                    onClick={onClickPrivacyPolicy}
                  >
                    Privacy Policy
                  </span>
                  <span
                    className="w-fitt text-xs text-charcoal cursor-pointer"
                    onClick={onClickUserAgreement}
                  >
                    User Agreement
                  </span>
                </div>
                <p className="text-xs">
                  © Admired Leadership {now.getFullYear()}
                </p>
              </div>
            </div>
            {/* /sidebar */}

            {/* chat body */}
            <div className="w-full pt-4 pb-4 lg:w-3/4 lg:pl-8">
              <hgroup className="flex items-center gap-2">
                <button
                  className="h-4 flex flex-col justify-between px-3 lg:hidden"
                  onClick={handleHistory}
                >
                  <span
                    style={{ height: '2px' }}
                    className="w-5 rounded bg-black"
                  />
                  <span
                    style={{ height: '2px' }}
                    className="w-5 rounded bg-black"
                  />
                  <span
                    style={{ height: '2px' }}
                    className="w-5 rounded bg-black"
                  />
                </button>
                <UserMenu />
              </hgroup>
              <div className="chat-container">
                <div className="chat-group">
                  {!messages.length && (
                    <Intro prompts={prompts} onPromptSelect={onPromptSelect} />
                  )}
                  {messages && (
                    <>
                      {messages.map((chatMessage) =>
                        chatMessage.from === 'alex' ? (
                          <Recipient message={chatMessage} />
                        ) : (
                          <Sender>{chatMessage.message}</Sender>
                        )
                      )}
                      {!!(messages.length % 2) && <Recipient />}
                    </>
                  )}
                  <div id="anchor" aria-hidden="true"></div>
                </div>
                <form
                  className="chat-input"
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage(message);
                  }}
                >
                  <label>
                    <textarea
                      onChange={(e) => {
                        handleTextareaChange(e);
                        setMessage(e.target.value);
                      }}
                      onBlur={handleTextareaChange}
                      onKeyDown={handleTextareaKeyPress}
                      value={message}
                      type="text"
                      placeholder="Ask a question"
                      disabled={!!(messages.length % 2)}
                    />
                    <button type="submit">
                      <img src={ArrowRight} alt="Send" />
                    </button>
                  </label>
                  <div className="text-right hidden lg:block">
                    <span className="text-xs text-middle-gray">
                      <b>Shift + Return</b> to add a new line
                    </span>
                  </div>
                </form>
              </div>
              <div className="mt-2 text-center">
                <p className="text-sm">
                  ALEX can make mistakes. Please double-check responses.
                </p>
              </div>
            </div>
            {/* /chat body */}
          </div>
        </section>
      ) : (
        <GetAccess />
      )}
    </>
  );
};

export default Chat;
